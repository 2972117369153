.custCard {
    @apply flex flex-col items-center w-[21.87rem] h-[30.43rem] rounded-xl m-5 -mt-20 bg-lightGrey transition duration-200 ease-out hover:border-primeBlue hover:shadow-xl hover:scale-100
}
.box-one { 
    @apply flex flex-col justify-center w-[21.87rem] h-[10.75rem] rounded-xl bg-gradient-to-b from-secondGreen to-primeGreen
}
.plan-name {
    @apply text-center font-pop600 text-2xl
}
.plan-price {
    @apply text-darkTeal text-center font-pop600 text-4xl mt-4 mb-2
}
.plan-limit {
    @apply text-center text-base font-pop500 -mt-2.5 text-darkTeal
}
.plan-includes {
    @apply text-darkTeal text-center text-base font-pop600
}
.plan-list {
    @apply mt-3 text-center text-base font-pop400 text-darkTeal
}
.items {
    @apply flex justify-center items-center py-3 px-16 border-t border-solid border-greyThree
}
.btn-box {
    @apply flex justify-center items-center text-base font-pop600
}
.button {
    @apply w-[18.125rem] h-[3.125rem] bg-darkTeal rounded-xl text-base font-pop600 text-white
}